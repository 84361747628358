(function() { 'use strict';
  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  Inputmask.extendAliases({
    'phone-mask': {
      regex: '(\\+7|8) \\([0-689]\\d{2}\\) \\d{3}(\\-\\d{2}){2}',
      placeholder: '+7 (___) ___-__-__',
      keepStatic: true
    }
  });

  // Phone
  $('input[type="tel"], input[autocomplete="tel"]').inputmask('phone-mask');


  // Scroll to
  // ---------
  $DOCUMENT.on('click.js-scroll-to', '.js-scroll-to', function(e) {
    e.preventDefault();

    var $lnk = $(this),
        $elemToScroll = $($lnk.attr('href').substr($lnk.attr('href').lastIndexOf('#'))),
        speed = $lnk.data('scrollSpeed') || 150,
        offset = $lnk.data('scrollOffset') || 0;

    $WINDOW.scrollTo($elemToScroll, {duration: speed, offset: offset});
  });


  // Callback content row show
  // -------------------------
  $DOCUMENT.on('click.callback-content-link', '.callback-form .form-row-content-link span', function(e) {
    e.preventDefault();

    $(this).parents('.callback-form').addClass('callback-form-with-content');
  });


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    loop: true,
    margin: 0,

    infobar: false,
    buttons: [
      'close',
      false,
      false,
      false
    ],

    smallBtn: false,

    protect : true,

    focusElement: '.js-autofocus-inp'
  });

  $('[data-fancybox]').fancybox();


  // Slick sliders
  // -------------
  var slickSettings = {
    autoplay: true,
    autoplaySpeed: 6000,
    prevArrow: '<div class="slick-prev"></div>',
    nextArrow: '<div class="slick-next"></div>',
    customPaging: function() { return $('<div />'); },
    draggable: false
  };

  // Main rotator
  $('.main-rotator-container').slick($.extend({}, slickSettings, {
    centerMode: true,
    centerPadding: '0px',
    fade: true,
    dots: true,
    slide: '.main-rotator-slide-container',
    appendArrows: '#main-rotator-controls',
    appendDots: '#main-rotator-dots > div > div'
  }));

  // Main gallery rotator
  $('.main-gallery-rotator').each(function() {
    var $self = $(this);

    $self.slick($.extend({}, slickSettings, {
      dots: true,
      slide: '.main-gallery-image-container',
      prevArrow: '<div class="slick-prev"><div class="button-style"><div></div></div></div>',
      nextArrow: '<div class="slick-next"><div class="button-style"><div></div></div></div>',
      appendArrows: $self.find('.main-gallery-controls'),
      appendDots: $self.find('.main-gallery-dots > div > div')
    }));
  });


  // Portfolio filter
  // ----------------
  var $portfolioGrid = $('.portfolio-page-block-container .portfolio-items').isotope({
    itemSelector: '.list-view-item',
    layoutMode: 'fitRows',
    percentPosition: true,
    transitionDuration: '0.5s',
    containerStyle: null
  });

  $DOCUMENT.on('click.portfolio-filter', '.portfolio-page-block-container .quick-link-list a', function(e) {
    e.preventDefault();

    var $self = $(this),
        filterValue = $self.data('filterValue');

    if( filterValue !== '*' )
      filterValue = '[data-filter-section="' + filterValue + '"]';

    $self.addClass('active').parent().siblings().children('a').removeClass('active');
    $portfolioGrid.isotope({ filter: filterValue });
  });


  // Callbacks
  // ---------
  CALLBACKS['reachGoalPhoneOrder'] = function() {
    if( typeof yaCounter46020042 === 'object' )
      yaCounter46020042.reachGoal('phone_order');
  };

})();